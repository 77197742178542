'use strict'

const _ = require('lodash')
const CONTENT_AREA_MARKER_NAME_IN_DOM = 'content-area-marker'

const contentAreaUtil = {
    getContentAreaMarkingElements(contentArea, compId) {
        const {width, alignment} = contentArea

        return ['div', {
            style: {
                visibility: 'hidden', position: 'absolute', width,
                marginLeft: `calc((100% - ${width}px) * ${alignment})`
            },
            key: CONTENT_AREA_MARKER_NAME_IN_DOM,
            [`data-${CONTENT_AREA_MARKER_NAME_IN_DOM}`]: 'true',
            'data-aid': `${CONTENT_AREA_MARKER_NAME_IN_DOM}-${compId}`
        }]
    },
    getContentAreaMarkerChildOnNode(domNode) {
        return domNode.querySelector(`[data-${CONTENT_AREA_MARKER_NAME_IN_DOM}]`)
    },
    getContentAreaMarkerForElement(domNode) {
        const {parentElement} = domNode || {}
        if (!parentElement) {
            return null
        }

        if (parentElement.dataset.meshInternal) {
            return contentAreaUtil.getContentAreaMarkerForElement(parentElement)
        }

        return _.find(parentElement.querySelectorAll(`[data-${CONTENT_AREA_MARKER_NAME_IN_DOM}]`), {parentElement})
    }
}

module.exports = contentAreaUtil