'use strict'

module.exports = {
    errorTypes: {
        VIDEO_GENERAL_ERROR: 'generalError',
        NO_VIDEO_FOUND: 'noVideoError',
        NO_HLS_VIDEO: 'noHlsVideoError'
    },
    playbackTypes: {
        IDLE: 'idle',
        LOADING: 'loading',
        READY: 'ready',
        STOPPED: 'ready',
        PLAY_ENDED: 'ended',
        PLAY_PREVIEW: 'play_preview',
        PLAYING: 'playing',
        PAUSED: 'paused',
        PAUSED_BY_SERVICE: 'paused_by_service',
        SEEK_PLAYING: 'seek_playing',
        SEEK_PAUSED: 'seek_paused',
        SEEKING: 'seeking',
        SEEKING_ENDED: 'seek_ended',
        WAITING: 'waiting',
        STOPPING: 'stopping',
        ERROR: 'error'
    },
    eventTypes: {
        MOUNT: 'mount',
        UPDATE: 'update',
        LOAD: 'load',
        PLAYSTATE: 'playstate',
        VOLUME: 'volume',
        RATE: 'rate',
        TIME_UPDATE: 'timeupdate',
        PROGRESS: 'progress',
        ERROR: 'error'
    },
    availabilityReadyStates: {
        IN_PROCESS: 'in_process',
        PLAYING_PREVIEW: 'playing_preview',
        IDLE: 'idle',
        NO_VIDEO: 'no_video'
    },
    playback: {
        SUPPORTED_MEDIA_ATTRIBUTES: ['autoplay', 'mute', 'loop']
    },
    supportedParentProps: ['mediaBackgroundPadding'],
    clipPathPolygonSquare: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    balataConsts: {
        BALATA: 'balata',
        MEDIA: 'media',
        MEDIA_PADDING: 'mediaPadding',
        IMAGE: 'image',
        CONTENT: 'content',
        POSTER: 'poster',
        BG_IMAGE: 'bgimage',
        VIDEO: 'video',
        OVERLAY: 'overlay',
        UNDERLAY: 'underlay',
        BG_COLOR: 'bgcolor',
        ZOOM_SELECTORS: ['.bgImage', '.bgVideo'],
        PARALLAX_SELECTORS: ['.bgImage', '.bgVideo'],
        REVEAL_SELECTORS: ['.bgImage', '.bgVideo'],
        BLUR_SELECTORS: ['.bgImage', '.bgVideo'],
        FADE_SELECTORS: ['.bgImage', '.bgVideo', '.bgColor', '.bgOverlay'],
        overlay: 'overlayTransforms',
        media: 'mediaTransforms'
    },
    defaultMediaStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    popupMediaStyle: {
        position: 'fixed',
        pointerEvents: 'auto',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    }
}
